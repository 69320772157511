import React from 'react'
import { Link } from "gatsby";

const Switcher = ({ path }) => {
  return (
    <div className="language-switcher">
      {
        // si path contient /en/ alors on affiche un lien sans /en/
        // sinon on affiche un lien avec /en/
        path.includes("/en") ? (
          <Link to={path === "/en" ? '/' : path.replace("/en", "")}>FR</Link>
        ) : (
          <Link to={`/en${path}`}>EN</Link>
        )
      }
    </div>
  );
};

export default Switcher;