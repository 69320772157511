const React = require("react");
const Layout = require("./src/components/layout.jsx").default;
const eventBus = require('./src/utils/eventBus.js').default;
const textAnimation = require("./src/utils/textAnimation.js").default;

exports.onClientEntry = () => {
  window.onload = () => {
    textAnimation.init();
  if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    } 
  }
}
exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  // eventBus.dispatch('RouteUpdate', {location, prevLocation});
};

exports.shouldUpdateScroll = () => { return false; };