exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../../../src/pages/a_propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-activities-js": () => import("./../../../src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-evenement-js": () => import("./../../../src/pages/evenement.js" /* webpackChunkName: "component---src-pages-evenement-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-les-suites-js": () => import("./../../../src/pages/les_suites.js" /* webpackChunkName: "component---src-pages-les-suites-js" */),
  "component---src-pages-obligations-conditions-generales-js": () => import("./../../../src/pages/obligations/conditions_generales.js" /* webpackChunkName: "component---src-pages-obligations-conditions-generales-js" */),
  "component---src-pages-obligations-mention-legales-js": () => import("./../../../src/pages/obligations/mention_legales.js" /* webpackChunkName: "component---src-pages-obligations-mention-legales-js" */),
  "component---src-pages-restauration-js": () => import("./../../../src/pages/restauration.js" /* webpackChunkName: "component---src-pages-restauration-js" */)
}

