import React from "react";
import { Link } from "gatsby";

const ListLink = ({ path, lang }) => {
  return (
    <ul className="listLink">
      <li className="bi-link_about">
        <Link to={(lang === "en" ? '/' + lang : "") + "/a_propos"}>
          {lang === "en" ? "About" : "À propos"}
        </Link>
      </li>
      <li className="bi-link_suites">
        <Link to={(lang === "en" ? '/' + lang : "") + "/les_suites"}>
          {lang === "en" ? "The suites" : "Les Suites"}
        </Link>
      </li>
      <li className="bi-link_restauration">
        <Link to={(lang === "en" ? '/' + lang : "") + "/restauration"}>
          {lang === "en" ? "Food" : "Restauration"}
        </Link>
      </li>
      <li className="bi-link_activities">
        <Link to={(lang === "en" ? '/' + lang : "") + "/activities"}>
          {lang === "en" ? "Activities" : "Activités"}
        </Link>
      </li>
      <li className="bi-link_events">
        <Link to={(lang === "en" ? '/' + lang : "") + "/evenement"}>
          {lang === "en" ? "Events" : "Événements"}
        </Link>
      </li>
      <li className="bi-link_contact">
        <Link to={(lang === "en" ? '/' + lang : "") + "/contact"}>
          {lang === "en" ? "Contact and access" : "Contact et accès"}
        </Link>
      </li>
    </ul>
  );
};

export default ListLink;
