import gsap from 'gsap';
import eventBus from './eventBus';
const imagesParallax = () => {
  eventBus.on("mutation", (e)=>{
    const pictures = document.querySelectorAll("picture");
    pictures.forEach((picture) => {
      // Get all data attributes
      const dataAttributes = picture.dataset;
      if (
        dataAttributes.hasOwnProperty("parallax") &&
        dataAttributes.parallax === "true"
        ) {
          const picTop = picture.getBoundingClientRect().top
          const picBottom = picture.getBoundingClientRect().bottom

          if(picTop <= window.innerHeight + 100 && picBottom > 0){
            const direction = dataAttributes.pDirection === "bottom" ? 1 : -1
            const speed = parseFloat(dataAttributes.pVitesse)
            const img =  picture.querySelector("img")
            img.style.transform = `scale(1.2) translateY(${ direction * speed * e.scroll/10 - offsetValue(img)}px)`
          }
      }
    });
  })
  };

  const blockParallax = () => {
    // select all elements with data-parallax attribute
    const elements = document.querySelectorAll("[data-parallax]");
    // remove all picture elements from the array
    eventBus.on("mutation", (e)=>{
    elements.forEach((element) => {
        const rect = element.getBoundingClientRect()
        const bot = rect.bottom
        
        if (element.tagName === "PICTURE") {
        } else {
          const dataAttributes = element.dataset;
          const picTop = rect.top
          
          if(picTop <= window.innerHeight + 100 && bot > 0){
            const direction = dataAttributes.pDirection === "bottom" ? 1 : -1
            const speed = parseFloat(dataAttributes.pVitesse)
            element.style.transform = `translate(${offsetXValue(element)}px,${ direction * speed * e.scroll/10 + offsetValue(element)}px)`
          }
        }
      });
    })
  };

  const offsetXValue = (el) => {
    let value = gsap.getProperty(el, "x") ? gsap.getProperty(el, "x") : 0;
    //console.log(el, value)
    return value;
  };

  const offsetValue = (el) => {
    let value = gsap.getProperty(el, "y") ? gsap.getProperty(el, "y") : 0;
    //console.log(el, value)
    return value;
  };

  export default {
    imagesParallax,
    blockParallax,
    offsetValue,
  }