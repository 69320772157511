import { Link } from 'gatsby'
import React from 'react'

const BottomList = ({path}) =>{
    const lang = path.includes("/en") ? "/en/obligations" : "/obligations";
    return (
        <div className="bi_footer_bottom">
            <p className="bi_footer_bottom_credits">{path.includes("/en") ? '©COPYRIGHT 2022 Les Suites de l’Île Barbe' : '©COPYRIGHT 2022 Les Suites de l’Île Barbe'}</p>
            <ul className="bi_footer_bottom_lists">
                <li><Link to={lang + '/conditions_generales'}>{path.includes("/en") ? 'Terms and conditions' : 'Conditions générales'}</Link></li>
                <li><Link to={lang + '/mention_legales'}>{path.includes("/en") ? 'Legal Notice' : 'Mentions légales'}</Link></li>
                <li>{path.includes("/en") ? 'Website mabe by' : 'Site réalisé par' } <a href="https://bihua.fr/" target="_blank">Bihua</a></li>
            </ul>
        </div>
    )
}

export default BottomList